import 'bootstrap';
import $ from 'jquery';
import SessionService from './SessionService';
import DataService from './DataService';
const sessionService = new SessionService();
const dataService = new DataService();

sessionService.handleSession();

const grid = $('[data-bind-grid]');
const type = grid.data('bind-grid');
const tBody = grid.children('tbody');

$(function () {
	$('[data-toggle="tooltip"]').tooltip();
});

const bindGrid = async () => {
	if (type !== 'analysis') {
		const data = await dataService.list(type);
		tBody.empty();

		data.sort((a, b) => (a.title.replace(/[^\w\s]/gi, '') > b.title.replace(/[^\w\s]/gi, '') ? 1 : -1));

		if (type === 'news') {
			data.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
		}

		if (type === 'contents') {
			data.forEach((item, i) => {
				if (item.sortierung === '') {
					item.sortierung = 0;
				}
			});
			data.sort((a, b) => (a.sortierung > b.sortierung ? 1 : b.sortierung > a.sortierung ? -1 : 0));
		}

		data.forEach((item) => {
			const badgeColor = item.status == 'Entwurf' ? 'warning' : 'success';
			tBody.append(`<tr data-id="${item.id}">
	    <th scope="row" class="text-truncate" style="max-width:300px;">${item.title}<br><small>${item.subTitle || ''}</small></th>
	    <td><span class="badge badge-${badgeColor}">${item.status}</span></td>
	    <td>
	    <a class="btn-sm btn btn-primary" href="/${type}_edit.html#${item.id}" >bearbeiten</a>
	    <button class="btn-sm btn ml-1 btn-danger" type="button" data-toggle="delete" data-id="${item.id}">löschen</button>
	    </td>
	    </tr>`);
		});

		$('[data-toggle="delete"]').on('click', (event) => {
			const id = $(event.currentTarget).data('id');
			if (confirm('Möchten Sie den Eintrag wirklich löschen?')) {
				dataService.delete(type, id).then(bindGrid);
			}
		});
	}
};

if ($('[data-bind-grid]').length) {
	bindGrid();
}
