import 'bootstrap';
import $ from 'jquery';
import SessionService from './SessionService';
import DataService from './DataService';
const sessionService = new SessionService();
const dataService = new DataService();

sessionService.handleSession();

const grid = $('[data-bind-grid]');
const type = grid.data('bind-grid');
const tBody = grid.children('tbody');

const getTypeFromPath = function (path) {
	try {
		const fileName = path.split('/')[1].replace('.html', '');
		switch (fileName) {
			case 'spur':
				return 'locations';
			case 'hintergrund':
				return 'contents';
			case 'aktuell':
				return 'news';
			default:
				return;
		}
	} catch (error) {
		console.error(error);
		return;
	}
};

const getTitle = function (data, type, id) {
	try {
		if (type) {
			let listOfType = data[type];

			var result = listOfType.filter((obj) => {
				return obj.id === id;
			});
			return result[0].title;
		}
	} catch (error) {
		return '';
	}

	return '';
};

$(function () {
	$('[data-toggle="tooltip"]').tooltip();
});

const bindGrid = async () => {
	let data = {};

	// data.analysis = await dataService.list(type);
	// data.locations = await dataService.list('locations');
	// data.contents = await dataService.list('contents');
	// data.news = await dataService.list('news');

	await Promise.all([dataService.list(type), dataService.list('locations'), dataService.list('contents'), dataService.list('news')]).then(
		(values) => {
			data.analysis = values[0];
			data.locations = values[1];
			data.contents = values[2];
			data.news = values[3];
		}
	);
	tBody.empty();
	data.analysis.sort((a, b) => (a.allTime > b.allTime ? -1 : 1));

	const date = new Date();
	let sums = {
		allTime: 0,
		year: 0,
		month: 0,
		today: 0,
	};
	data.analysis.forEach((item) => {
		sums.allTime += item.allTime || 0;
		sums.year += item[date.getFullYear()] || 0;
		sums.month += item[`${date.getFullYear()}-${date.getMonth() + 1}`] || 0;
		sums.today += item[`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`] || 0;

		var title = getTitle(data, getTypeFromPath(item.page), item.id);
		//console.log('title', title);
		tBody.append(`<tr data-id="${item.id}">
	    <th scope="row" class="text-truncate" style="max-width:300px;">${title}<br><small>${item.page || ''}</small></th>
	    <td>${item.allTime || 0}</td>
		<td>${item[date.getFullYear()] || 0}</td>
		<td>${item[`${date.getFullYear()}-${date.getMonth() + 1}`] || 0}</td>
		<td>${item[`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`] || 0}</td>
	    </tr>`);
	});
	tBody.append(`<tr>
	    <th>Summe</th>
	    <th>${sums.allTime}</td>
		<th>${sums.year}</td>
		<th>${sums.month}</td>
		<th>${sums.today}</td>
	    </tr>`);
};

if ($('[data-bind-grid]').length) {
	bindGrid();
}
