import 'regenerator-runtime/runtime';
const axios = require('axios');
import SessionService from './SessionService';
const sessionService = new SessionService();

export default class DataService {
	constructor() {
		this.AUTH_INFO = { headers: { Authorization: `Bearer ${sessionService.getIdToken()}` } };
		this.API_URL = 'https://api.spuren-in-herten.de';
	}

	async list(type) {
		var data = this.getFromCache(type);

		// if (type === 'analytics' && data) {
		// 	return data;
		// }

		data = await axios.get(`${this.API_URL}/${type}`);
		this.putToCache(type, data.data);
		return data.data;
	}

	async get(type, id) {
		var data = {};
		if (id) {
			data = await axios.get(`${this.API_URL}/${type}/${id}`);
		}
		return data.data;
	}

	async put(type, body) {
		try {
			await axios.put(`${this.API_URL}/${type}`, body, this.AUTH_INFO);
			this.purgeCache(type);
		} catch (error) {
			console.error(error);
		}
	}

	async update(type, body) {
		try {
			await axios.post(`${this.API_URL}/${type}/${body.id}`, body, this.AUTH_INFO);
			this.purgeCache(type);
		} catch (error) {
			console.error(error);
		}
	}

	async delete(type, id) {
		await axios.delete(`${this.API_URL}/${type}/${id}`, this.AUTH_INFO);
		this.purgeCache(type);
	}

	putToCache(key, content) {
		localStorage.setItem(key, JSON.stringify(content));
	}

	getFromCache(key) {
		return JSON.parse(localStorage.getItem(key));
	}

	purgeCache(key) {
		localStorage.removeItem(key);
	}
}
